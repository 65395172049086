// 新闻资讯列表
import { reqMoreData } from "@/api";

const state = {
  res: {}
}
const mutations = {
  getMoreNews(state, res) {
    state.res = res;
  }
}
const actions = {
  // 获取新闻列表数据
  async getNewsWikiList({ commit }, data) {
    let result = await reqMoreData(data)
    commit("getMoreNews", result);
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
