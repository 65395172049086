<template>
  <div class="wiki">
    <div class="wiki_left">
      <div class="con_left">
        <el-skeleton :rows="6" animated v-if="skeleton" />
        <el-breadcrumb separator="/" v-else>
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item v-if="type != '6'" :to="{ path: '/news' }">新闻资讯</el-breadcrumb-item>
          <el-breadcrumb-item v-if="type == '6'" :to="{ path: '/help/0' }">帮助中心</el-breadcrumb-item>
          <el-breadcrumb-item
            :to="{ path: `${type == 3 ? '/company/3' : type == 4 ? '/company/4' : type == 5 ? '/company/5' : type == 6 ? '/help/6' : '/company/8'}` }">
            {{ type == 3 ? '公司动态' : type == 4 ? '新闻热点' : type == 5 ? '政策法规' :
            type ==
            6 ? '行业攻略' : '专题百科' }}
          </el-breadcrumb-item>
          <el-breadcrumb-item>{{ news.title }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="con">
          <em class="title">{{ news.title }}</em>
          <div class="time">{{ news.createTime }}</div>
          <div class="new" v-html="news.content">
          </div>
        </div>
        <div class="foot">
          <div @click="prev">
            上一篇：<span :class="newsDetail.preId == 0 ? 'none' : ''">{{ newsDetail.preId != 0 ? newsDetail.preName : '无'
              }}</span>
          </div>
          <div style="text-align:right;" @click="next">
            下一篇：<span :class="newsDetail.quirId == 0 ? 'none' : ''">{{ newsDetail.quirId != 0 ? newsDetail.quirName : '无'
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <WikiNewsDetail :rightData="newsDetail" />
  </div>
</template>

<script>
import WikiNewsDetail from './components/WikiNewsDetail' //新闻详情
// 新闻详情数据
import { reqNewsDetail } from "@/api";
export default {
  name: 'NewsDetail',
  components: {
    WikiNewsDetail
  },
  metaInfo() { // meta-title设置
    return {
      title: this.pageTitle,
      meta: [
        {
          name: "keyWords",
          content: this.pagekeyWords,
        },
        {
          name: "description",
          content: this.pageDescription,
        },
      ],
    };
  },
  mounted() {
    this.id = this.$route.params.id
    this.init()
  },
  data() {
    return {
      id: '',
      type: this.$route.query.type || null, // 如果有type且为8 则是专题百科类型的新闻
      encyclopediaId: this.$route.query.encyclopediaId || null, // 如果有encyclopediaId 则是专题百科类型的新闻
      pageTitle: "", // 标题
      pagekeyWords: "", // 关键字
      pageDescription: "", // 描述
      newsDetail: {}, // 新闻详情
      news: {}, // 详情内容
      skeleton: true // 骨架屏效果
    }
  },
  watch: {
    $route() {
      this.type = this.$route.query.type || null
      this.id = this.$route.params.id
      this.encyclopediaId = this.$route.query.encyclopediaId || null
      this.init()
    }
  },
  methods: {
    // 新闻详情数据
    async init() {
      let params = {
        id: this.id,
        isHasAd: 1,
        adNum: 3,
        type: this.type,
      }
      if (this.encyclopediaId != null && this.type == 8) {
        params.encyclopediaId = this.encyclopediaId
      } else {
        delete params.encyclopediaId
      }
      let res = await reqNewsDetail(params)
      if (res != undefined && res.code == 10000) {
        this.skeleton = false
        this.newsDetail = res.data
        if (this.type && this.type == 8) {
          this.newsDetail.type = this.type
        }
        this.news = res.data.article
        this.pageTitle = this.news.title
        this.pagekeyWords = this.news.keywords
        this.pageDescription = this.news.description
      }
    },
    // 上一篇
    prev() {
      if (this.newsDetail.preId != 0) {
        this.$router.push({ path: `/article/article/${this.newsDetail.preId}`, query: { type: this.type, encyclopediaId: this.encyclopediaId } })
      }
    },
    // 下一篇
    next() {
      if (this.newsDetail.quirId != 0) {
        this.$router.push({ path: `/article/article/${this.newsDetail.quirId}`, query: { type: this.type, encyclopediaId: this.encyclopediaId } })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.con {
  /deep/ blockquote {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
  }
}


.wiki {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  letter-spacing: 0.5px;

  .wiki_left {
    width: 800px;
    margin-right: 20px;
    color: #333333;

    .wiki_title {
      background: #FFFFFF;
      padding: 20px 20px 0;
      margin-bottom: 20px;
      border-radius: 6px;
      height: 62px;
      box-sizing: border-box;
    }

    /deep/.el-breadcrumb {
      height: 22px;
      font-size: 16px;
      line-height: 22px;
      overflow: hidden;
    }

    /deep/.el-breadcrumb__item:last-child {
      width: 510px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-break: break-all;
    }

    /deep/.el-breadcrumb__inner a,
    /deep/.el-breadcrumb__inner.is-link,
    /deep/.el-breadcrumb__separator {
      font-weight: 400;
      color: #333;
    }

    /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner,
    /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
    /deep/.el-breadcrumb__inner {
      color: #333;
    }

    /deep/.el-breadcrumb__inner.is-link:hover {
      color: #777;
    }

    .left_nev {
      padding: 20px;
      height: 211px;
      background: #FFFFFF;
      border-radius: 6px;
      display: flex;
      box-sizing: border-box;

      >img {
        width: 340px;
        height: 170px;
        background: #F5F7FA;
        border-radius: 6px;
        margin-right: 20px;
      }

      >div {
        flex: 1;
      }

      span {
        font-size: 14px;
        color: #666666;
        line-height: 20px;
      }

      .icon {
        /*margin-top: 5px;*/
        width: 18px;
        height: 12px;
      }

      .con {
        font-size: 14px;
        color: #666666;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        word-break: break-all;
      }
    }

    .litleTitle {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      margin: 40px 0 0 0;
    }

    .neirong {
      height: 168px;
      margin-top: 20px;

      .con {
        margin: 10px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        word-break: break-all;
      }

      .title {
        height: 22px;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
    }

    .neirong:hover .title {
      color: #E40012;
      font-weight: 700;
    }

    .con_left {
      background: #FFFFFF;
      padding: 20px;
      padding-bottom: 100px;
      margin-bottom: 20px;
      border-radius: 6px;
      box-sizing: border-box;

      .con {
        padding-top: 20px;

        .title {
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 0.75px;
        }

        .time {
          margin: 10px 0 20px 0;
        }

        .new {
          line-height: 24px;
          margin-bottom: 40px;
          font-size: 14px;
          letter-spacing: 0.5px;

          /deep/img {
            max-width: 760px;
          }

          /deep/p {
            margin: 10px 0;
            line-height: 1.5;
            // text-indent: 28px;
          }

          /deep/hr {
            cursor: pointer;
            display: block;
            height: 0px;
            border: 0;
            border-top: 3px solid #ccc;
            margin: 20px 0;
          }

          /deep/h1 {
            margin: 10px 0;
            line-height: 1.5;
          }

          /deep/th {
            border-right: 1px solid #ccc;
            padding: 3px 5px;
            min-height: 30px;
            height: 30px;
            border-bottom: 2px solid #ccc;
            text-align: center;
            background-color: #f1f1f1;
          }
        }

        /deep/table {
          margin: 10px 0;
          line-height: 1.5;
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
        }

        /deep/td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
          padding: 3px 5px;
          min-height: 30px;
          height: 30px;
        }
      }

      .foot {
        display: flex;
        justify-content: space-between;

        div {
          width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          word-break: break-all;
        }

        div:hover span {
          color: #E40012;
          font-weight: 700;
          cursor: pointer;
        }

        div:hover .none {
          color: #333;
          font-weight: 400;
          cursor: context-menu;
        }

        span {
          height: 24px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
