<template>
  <div class="wiki">
    <!-- 专题百科详情 -->
    <div class="wiki_left">
      <div class="wiki_title">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/news' }">新闻资讯</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/company/8' }">专题百科</el-breadcrumb-item>
          <el-breadcrumb-item>{{wikiDetail.encyclopediaDO.title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="left_nev">
        <img :src="wikiDetail.encyclopediaDO.coverImg" alt="">
        <div>
          {{wikiDetail.encyclopediaDO.title}}
          <div style="margin:10px 0">
            <img class="icon" src="@/assets/img/icons/xw_look.png" alt="">
            <span>{{wikiDetail.encyclopediaDO.visits}}</span>
          </div>
          <div class="con">{{wikiDetail.encyclopediaDO.description}}</div>
        </div>
      </div>
      <div class="litleTitle">{{wikiDetail.encyclopediaDO.title}}相关资讯</div>
      <ul>
        <li class="left_nev neirong" v-for="item,i in wikiDetail.adviceList" :key="i"
          @click="$router.push({ path: `/article/article/${item.id}`, query: { type: item.type,encyclopediaId:item.encyclopediaId } })">
          <div>
            <div class="title">{{item.title}}</div>
            <div class="con">{{item.description}}</div>
            <div>
              <span>{{item.createTime}}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="litleTitle">{{wikiDetail.encyclopediaDO.title}}相关知识</div>
      <ul>
        <li class="left_nev neirong" v-for="item in wikiDetail.knowledgeList" :key="item.id"
          @click="$router.push({ path: `/article/article/${item.id}`, query: { type: item.type,encyclopediaId:item.encyclopediaId } })">
          <div>
            <div class="title">{{item.title}}</div>
            <div class="con">{{item.description}}</div>
            <div>
              <span>{{item.createTime}}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="litleTitle">热门专题</div>
      <ul class="detail">
        <li v-for="item in wikiDetail.specialList" :key="item.id"
          @click="$router.push({ name: 'wikiDetail', query: { id: item.id } })">
          <div class="news_div">
            <img :src="item.coverImg" alt="">
          </div>
          <div class="content">
            <span>{{item.title}}</span>
            <div class="wiki_c webkit-box-3">{{item.description}}</div>
          </div>
        </li>
      </ul>
    </div>
    <WikiNewsDetail :rightData="wikiDetail" />
  </div>
</template>

<script>
import WikiNewsDetail from './components/WikiNewsDetail' //新闻详情
// 获取专题百科/专题详情数据
import { reqEncyclopediasDetails } from '@/api'
export default {
  name: 'WikiDetail',
  components: {
    WikiNewsDetail
  },
  mounted() {
    this.getWikiList()
  },
  data() {
    return {
      id: this.$route.query.id,
      wikiDetail: { //专题百科数据
        encyclopediaDO: {}, // 专题百科详情
        adviceList: [], // 相关资讯
        knowledgeList: [], // 相关知识
        specialList: [], // 热门专题
      },
    }
  },
  watch: {
    // 监听路由获取id变化更新视图
    $route() {
      this.id = this.$route.query.id
      this.getWikiList()
    }
  },
  methods: {
    // 获取专题百科数据
    async getWikiList() {
      let perams = {
        id: this.id
      }
      let res = await reqEncyclopediasDetails(perams)
      console.log(res)
      if (res != undefined && res.code == 10000) {
        let { data } = res
        this.wikiDetail = data
        this.wikiDetail.encyclopediaDO = data.encyclopediaDO
        this.wikiDetail.adviceList = data.adviceList
        this.wikiDetail.knowledgeList = data.knowledgeList
        this.wikiDetail.specialList = data.specialList
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wiki {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  letter-spacing: 0.5px;

  .wiki_left {
    width: 800px;
    margin-right: 20px;
    color: #333333;

    .wiki_title {
      background: #FFFFFF;
      padding: 20px 20px 0;
      margin-bottom: 20px;
      border-radius: 6px;
      height: 62px;
      box-sizing: border-box;
    }

    /deep/.el-breadcrumb {
      height: 22px;
      font-size: 16px;
      line-height: 22px;
    }

    /deep/.el-breadcrumb__inner a,
    /deep/.el-breadcrumb__inner.is-link,
    /deep/.el-breadcrumb__separator {
      font-weight: 400;
      color: #333;
    }

    /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner,
    /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
    /deep/.el-breadcrumb__inner {
      color: #333;
    }

    /deep/.el-breadcrumb__inner.is-link:hover {
      color: #777;
    }

    .left_nev {
      padding: 20px;
      height: 211px;
      background: #FFFFFF;
      border-radius: 6px;
      display: flex;
      box-sizing: border-box;

      >img {
        width: 340px;
        height: 170px;
        background: #F5F7FA;
        border-radius: 6px;
        margin-right: 20px;
        object-fit: cover;
      }

      >div {
        flex: 1;
      }

      span {
        font-size: 14px;
        color: #666666;
        line-height: 20px;
      }

      .icon {
        width: 18px;
        height: 12px;
        margin-right: 4px;
      }

      .con {
        font-size: 14px;
        color: #666666;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        word-break: break-all;
      }
    }

    .litleTitle {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      margin: 40px 0 0 0;
    }

    .neirong {
      height: 168px;
      margin-top: 20px;
      cursor: pointer;

      .con {
        margin: 10px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        word-break: break-all;
        height: 66px;

      }

      .title {
        height: 22px;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-break: break-all;
      }
    }

    .neirong:hover .title {
      color: #E40012;
      font-weight: 700;
    }

    .con_left {
      background: #FFFFFF;
      padding: 20px;
      padding-bottom: 100px;
      margin-bottom: 20px;
      border-radius: 6px;
      box-sizing: border-box;

      .con {
        padding-top: 20px;

        .title {
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 0.75px;
        }

        .time {
          margin: 10px 0 20px 0;
        }

        .new {
          line-height: 24px;
          margin-bottom: 40px;
        }
      }

      .foot {
        display: flex;
        justify-content: space-between;

        div {
          width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          word-break: break-all;
        }

        div:hover span {
          color: #E40012;
          font-weight: 700;
        }

        span {
          height: 24px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    ul.detail {
      padding-top: 0;
      margin: 20px 0 0 0;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        width: 390px;
        background-color: #fff;
        margin: 0 0 20px 0;
        height: 280px;
        border-radius: 6px;
        cursor: pointer;

        img {
          display: block;
          width: 390px;
          height: 152px;
          background: #D8D8D8;
          border-radius: 6px;
          transition: all 0.6s ease;
          object-fit: cover;
        }

        .content {
          width: 390px;
          padding: 10px 20px 20px;
          background-color: #fff;
          margin: 0;
          box-sizing: border-box;
        }

        .wiki_c {
          margin-top: 9px;
          font-size: 14px;
          color: #666666;
          line-height: 22px;
        }
      }

      // li:hover img {
      //   transform: scale(1.2);
      // }
      li:hover span {
        color: #E40012;
        font-weight: 700;
      }
    }

    .webkit-box-3 {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      word-break: break-all;
    }
  }

  .news_div {
    overflow: hidden;
    border-radius: 6px;
  }
}
</style>
