import { reqTest } from "@/api"

const state = {
  test: '测试git'
}
const mutations = {}
const actions = {
  // 测试数据
  async testData({ commit }, data) {
    console.log('data', data);
    let result = await reqTest(data)
    console.log(result);
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
