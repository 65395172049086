<template>
  <ul>
    <li v-for="item in news.articleList">{{ item.description }}</li>
  </ul>
</template>

<script>
import { reqNewsDetail } from "@/api";
export default {
  name: "Seo",
  metaInfo: {
    title: "乐享数科—云票据_智能化票据生态平台",
    meta: [
      {
        name: "keywords",
        content:
          "票据,承兑汇票,银行承兑汇票,商业承兑汇票,供应链票据,标准化票据,票据平台,乐享数科,云票据",
      },
      {
        name: "description",
        content:
          "乐享数科旗下品牌—云票据供应链票据+标准化票据全周期管理系统，整合央国企及上市公司等核心企业资源，提供银行承兑汇票、商业承兑汇票、供应链票据、标准化票据等服务，创新承兑汇票融资渠道，助力小微企业票据融资，是专业、便捷、高效的供应链金融+票据服务平台.",
      },
    ],
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      params: {
        accessToken: "5017681700252f17247428b605230985",
        timestamp: "1677061960",
      },
      news: [],
    };
  },
  methods: {
    async getData() {
      let params = {
        id: 6954,
        isHasAd: 1,
        adNum: 3
      }
      let res = await reqNewsDetail(params)
      this.news = res.data
    },
  },
};
</script>

<style scoped></style>
