<template>  <div class="footer">
    <div class="footer_top">
      <div class="foot_l">
        <img
          style="width: 116px; height: 40px"
          src="@/assets/img/index/footLOGO.png"
          alt=""
        />
        <div class="foot_tl">乐享数科有限公司旗下供应链金融服务平台</div>
        <div class="flex">
          <div style="margin-right: 20px">
            <img src="@/assets/img/index/gf.png" class="top_img" alt="" />
            <div class="foot_text">扫码关注</div>
            <div class="foot_text">官方公众号</div>
          </div>
          <div>
            <img src="@/assets/img/index/w2.png" class="top_img" alt="" />
            <div class="foot_text">扫码关注</div>
            <div class="foot_text">微信服务号</div>
          </div>
        </div>
      </div>
      <div>
        <div class="flex">
          <div class="foot_c">
            <div class="font_s">关于我们</div>
            <div>
              <router-link to="/companyBrief" class="route_but"
                >公司简介</router-link
              >
            </div>
            <div>
              <router-link to="/history" class="route_but"
                >发展历程</router-link
              >
            </div>
            <div>
              <router-link to="/honor" class="route_but">荣誉资质</router-link>
            </div>
            <div>
              <router-link to="/partners" class="route_but"
                >合作伙伴</router-link
              >
            </div>
            <div>
              <router-link to="/contactUs" class="route_but"
                >联系我们</router-link
              >
            </div>
          </div>
          <div class="foot_r">
            <div class="font_s">联系我们</div>
            <div>电话：400-628-7087</div>
            <div>网址：www.cpiaoju.com</div>
            <div>邮箱：kefu@cpiaoju.com</div>
            <div>地址：北京市朝阳区东四环中路82号金长安C座5层</div>
          </div>
        </div>
        <div class="foot-b">
          <!-- <a
            href="https://xinyong.yunaq.com/certificate?site=www.cpiaoju.com&at=business"
            target="_blank"
          >
            <img src="@/assets/img/index/002.png" class="bot_img" alt="" />
          </a> -->
          <!-- <a
            target="cyxyv"
            href="https://v.yunaq.com/certificate?domain=www.cpiaoju.com&from=label&code=90030"
          >
            <img
              src="https://aqyzmedia.yunaq.com/labels/label_sm_90030.png"
              class="bot_img"
            />
          </a> -->
          <!-- <a
            href="https://si.trustutn.org/info?sn=217180704000654093792&certType=4"
            target="_blank"
          >
            <img src="@/assets/img/index/003.png" class="bot_img" alt="" />
          </a> -->
          <a
            target="cyxyv"
            href="https://v.yunaq.com/certificate?domain=www.cpiaoju.com&from=label&code=90020"
          >
            <img
              src="https://aqyzmedia.yunaq.com/labels/label_lg_90020.png"
              class="bot_img"
            />
          </a>
          <a
            id="_pingansec_bottomimagelarge_shiming"
            target="_blank"
            href="//si.trustutn.org/info?sn=237230831040944817457&certType=1"
          >
            <img
              src="//v.trustutn.org/images/cert/bottom_large_img.png"
              class="bot_img"
            />
          </a>
          <a
            href="https://ss.knet.cn/verifyseal.dll?sn=e21080611010883877aycf000000&ct=df&pa=gsvgl2nrWqdN8zl4"
            target="_blank"
          >
            <img
              src="@/assets/img/index/star4.png"
              class="bot_img"
              style="margin-right: 10px"
              alt=""
            />
          </a>
          <div>
            乐享数科有限公司 Copyright © 2014
            <a
              href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
              target="_blank"
              class="a_css"
              style="color: #999"
              >京ICP备14062166号</a
            >
          </div>
          <img
            src="@/assets/img/icons/police.png"
            class="bot_litle_img"
            alt=""
          />
          <div>
            <a
              href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502042175"
              target="_blank"
              class="a_css"
              style="color: #999"
              >京公网安备 11010502042175号</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.footer {
  color: #fff;
  height: 323px;
  background: #17181b;
  letter-spacing: 0.5px;
  line-height: 20px;
  font-size: 14px;
  padding: 40px 0;
  box-sizing: border-box;

  .footer_top {
    width: 1200px;
    height: 243px;
    margin: 0 auto;
    display: flex;
    color: #fff;
    position: relative;
    .foot_l {
      width: 325px;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      .foot_tl {
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 20px;
      }
      .foot_text {
        text-align: center;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.7);
        line-height: 17px;
      }
      .top_img {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
      }
    }
    .foot_c {
      width: 333px;
      height: 220px;
      .route_but {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7);
        line-height: 20px;
        text-decoration: none;
      }
      .route_but:hover {
        color: #e40012;
      }
      div {
        margin: 0 120px 10px;
      }
    }
    .foot_r {
      width: 453px;
      height: 220px;
      div {
        margin: 0 0 10px 120px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7);
        line-height: 20px;
      }
    }
    .foot-b {
      padding-left: 120px;
      display: flex;
      .bot_img {
        width: 34px;
        height: 14px;
        margin-right: 5px;
      }
      div {
        height: 17px;
        font-size: 12px;
        color: #999999;
        line-height: 17px;
      }
      .bot_litle_img {
        width: 12px;
        height: 14px;
        margin: 0 5px;
      }
    }
    img {
      display: block;
    }
  }
  .footer_top::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 123px;
    top: 13px;
    right: 540px;
    background: rgba(255, 255, 255, 0.3);
  }
}
.a_css {
  text-decoration: none;
}
.marBtm_20 {
  margin-bottom: 20px;
}
.marBtm_10 {
  margin-bottom: 10px;
}
.flex {
  display: flex;
}
.padLeft_20 {
  padding-left: 20px;
}
.font_s {
  font-size: 20px !important;
  font-weight: 700;
  color: #ffffff !important;
  line-height: 28px !important;
}
</style>
