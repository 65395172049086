import Vue from 'vue'
import App from './App.vue'
import router from "@/router"
import store from './store'
// Element-ui
import "@/plugins/element-ui"
// vue-clipboard —— 剪切板
import "@/plugins/vue-clipboard"
// normalize.css
import 'normalize.css/normalize.css'
// 管理头部标签
import MetaInfo from 'vue-meta-info'
// // default-passive-events
// import 'default-passive-events'
import resetMessage from '@/assets/js/message'
Vue.prototype.$message = resetMessage;

Vue.use(MetaInfo)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  mounted() {
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 10000)
  },
  router,
  store
}).$mount('#app')

var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?2a381341bac586d00460d1bd00a3ce71";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
