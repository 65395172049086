// 产品列表
import { reqProductList } from '@/api'

const state = {
  res: {}
}
const mutations = {
  getProduct(state, res) {
    state.res = res;
    // 在本地存一份
  }
}
const actions = {
  // 获取产品列表数据
  async getProductList({ commit }, data) {
    let result = await reqProductList(data)
    commit("getProduct", result);
    console.log(result)
    localStorage.setItem('productList', JSON.stringify(result.data))
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
