<template>  <div id="app">
    <Header />
    <router-view></router-view>
    <Footer v-show="isShowFooter" />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "@/components/Footer";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  computed: {
    // 登录、注册、忘记密码 —— 底部不同
    isShowFooter() {
      return (
        this.$route.path !== "/home/forget" &&
        this.$route.path !== "/home/register" &&
        this.$route.path !== "/home/login"
      );
    },
  },
};
</script>

<style lang="less">
#app {
  background-color: #f5f7fa;
  width: 100%;
  min-height: 100vh;
}

#nprogress .bar {
  background: #e40012 !important;
}

#nprogress .spinner-icon {
  border-top-color: #e40012 !important;
  border-left-color: #e40012 !important;
}
</style>
