<template>
  <div class="wiki_right">
    <div v-if="rightData.adList && rightData.adList.length != 0">
      <div v-for="item in rightData.adList" :key="item.id">
        <a v-if="item.link" :href="item.link" target="_blank" rel="noopener noreferrer">
          <img class="adv" :src="item.imgUrl" alt="" />
        </a>
        <img v-else class="adv" :src="item.imgUrl" alt="" />
      </div>

    </div>
    <div class="xianshi">
      <div style="color:#333;font-weight: 700;font-size:16px">相似推荐</div>
      <ul v-for="item in rightData.articleList" :key="item.id" @click="jump(item)">
        <li><span>{{item.title}}</span></li>
      </ul>
    </div>
    <div class="xianshi">
      <div style="color:#333;font-weight: 700;font-size:16px;">最新资讯</div>
      <ul v-for="item in rightData.articleNewList" :key="item.id" @click="jump(item)">
        <li>
          <div class="zuixin_div">
            <span>{{item.title}}</span>
            <em>{{item.createTime}}</em>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WikiNewsDetail',
  props: {
    rightData: Object
  },
  data() {
    return {

    }
  },
  methods: {
    // 跳转
    jump(item) {
      this.$router.push({ path: `/article/article/${item.id}`, query: { type: item.type } })
    }
  }
}
</script>

<style lang="less" scoped>
.wiki_right {
  width: 380px;

  img {
    width: 380px;
    height: 180px;
    background: #D8D8D8;
    border-radius: 6px;
    margin-bottom: 20px;
    object-fit: cover;
  }

  .xianshi {
    padding: 20px;
    width: 380px;
    height: 212px;
    background: #FFFFFF;
    border-radius: 6px;
    margin-bottom: 20px;
    box-sizing: border-box;
    color: #333;

    li {
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-break: break-all;
      display: flex;
      font-size: 14px;
      cursor: pointer;

      span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-break: break-all;
      }
    }

    li::before {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      background: #E40012;
      border-radius: 50%;
      margin-right: 11px;
      margin-top: 7px;
    }

    .zuixin_div {
      display: flex;
      justify-content: space-between;
      flex: 1;

      span {
        width: 217px;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-break: break-all;
        font-size: 14px;
      }

      em {
        color: #666;
      }
    }

    li:hover,
    li:active li:hover div,
    li:active div,
    li:hover em,
    li:active em {
      color: #E40012;
      font-weight: 700;
    }
  }
}
</style>
