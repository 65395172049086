// 当前这个模块：API进行统一管理
import request from './request'

// 例如：
// /cpiaoju-auth/lxgw/front/getRouters -- get -- 无参数
// 发请求：axios发请求返回结果Promise对象
export const reqTest = (data) => request({ url: `/json/login`, method: "POST", data })

// 退出登录  /sso/logout
export const reqLogout = () => request({ url: '/sso/logout', method: "POST" })

// 单点登录  /sso/cross-platform-login
export const reqCrossLogin = (data) => request({ url: '/sso/cross-platform-login', method: "POST", data })

// 新闻资讯 ——  新闻资讯页面数据  /news-info/newsInfo/newsData -- POST --
export const reqNewsData = (data) => request({ url: `/news-info/newsInfo/newsData`, method: "POST", data })

// 新闻资讯 ——  新闻详情数据  /news-info/newsInfo/detailedDetails -- POST --
export const reqNewsDetail = (data) => request({ url: `/news-info/newsInfo/detailedDetails`, method: "POST", data })

// 新闻资讯 ——  新闻列表数据  /news-info/newsInfo/moreData -- POST --
export const reqMoreData = (data) => request({ url: `/news-info/newsInfo/moreData`, method: "POST", data })

// 新闻资讯 ——  专题详情  /news-info/newsInfo/encyclopediasDetails -- POST --
export const reqEncyclopediasDetails = (data) => request({ url: `/news-info/newsInfo/encyclopediasDetails`, method: "POST", data })

// 乐享文件列表查询  /news-info/newsInfo/lxFile/listByType -- POST --
export const reqListByType = (data) => request({ url: `/news-info/lxFile/listByType`, method: "POST", data })

// 首页 ——  首页轮播数据  /news-info/newsInfo/queryBanner -- POST --
export const reqQueryBanner = (data) => request({ url: `/news-info/newsInfo/queryBanner`, method: "POST", data })

// 首页 ——  首页表单提交  /cpiaoju/cjt-product/list -- POST --
export const reqProductList = (data) => request({ url: `/cpiaoju/cjt-product/list`, method: "POST", data })

// 首页 ——  首页新闻数据  /news-info/newsInfo/newsConsultation -- POST --
export const reqNewsConsultation = (data) => request({ url: `/news-info/newsInfo/newsConsultation`, method: "POST", data })

// 首页 ——  省市数据  /utils/area/listByPid -- POST --
export const reqListByPid = (data) => request({ url: `/utils/area/listByPid`, method: "POST", data })


// 个人中心 ——  修改密码获取短信验证码 /sso/member/getShortMsg -- POST -- updatePassword
export const reqShortMsg = (data) => request({ url: `/sso/member/getShortMsg`, method: "POST", data })

// 个人中心 ——  修改密码提交 /sso/member/updatePassword -- POST --
export const reqUpdatePassword = (data) => request({ url: `/sso/member/updatePassword`, method: "POST", data })

// 个人中心 ——  获取邮箱验证码 /sso/member/sendEmailCode -- POST --
export const reqSendEmailCode = (data) => request({ url: `/sso/member/sendEmailCode`, method: "POST", data })

// 个人中心 ——  邮箱验证提交 /sso/member/emailVerify -- POST --
export const reqEmailVerify = (data) => request({ url: `/sso/member/emailVerify`, method: "POST", data })

// 个人中心 ——  邮箱是否已绑定 /sso/member/isBindEmail -- POST --
export const reqIsBindEmail = () => request({ url: `/sso/member/isBindEmail`, method: "POST" })

// 个人中心 —— 银行列表 /ent/bank/list -- POST -- 无参
export const reqBankList = () => request({ url: `/ent/bank/list`, method: "POST" })

// 个人中心 —— 对公账户列表 /ent/bankAccount/list -- POST -- 无参数
export const reqBankAccountList = (data) => request({ url: `/ent/bankAccount/list`, method: "POST", data })

// 个人中心 —— 删除对公账户 /ent/bankAccount/delete -- POST -- 有参
export const reqBankAccountDel = (data) => request({ url: `/ent/bankAccount/delete`, method: "POST", data })

// 个人中心 —— 查询对公账户详情 /ent/bankAccount/getById -- POST -- 有参
export const reqBankAccountDetail = (data) => request({ url: `/ent/bankAccount/getById`, method: "POST", data })

// 小额申请  /ent/bankAccount/applyValidation -- POST -- 有参
export const reqApplyValidation = (data) => request({ url: `/ent/bankAccount/applyValidation`, method: "POST", data })
// 小额鉴权 /ent/bankAccount/validationAmount -- POST -- 有参
export const reqValidationAmount = (data) => request({ url: `/ent/bankAccount/validationAmount`, method: "POST", data })


// 企业认证 —— 企业状态查询  /ent/company/queryStatus -- POST -- 请求头参数：Authorization
export const reqCompanyStatus = () => request({ url: `/ent/company/queryStatus`, method: "POST" })

// 企业认证 —— 查询企业信息  /ent/company/info -- POST -- 请求头参数：Authorization
export const reqComapnyInfo = () => request({ url: `/ent/company/info`, method: "POST" })

// 企业认证 —— 企业资料提交 /ent/company/submit -- POST -- 有参
export const reqcompanySubmit = (data) => request({ url: `/ent/company/submit`, method: "POST", data })

// 企业认证 —— 选择人脸方式 /ent/company/chooseFaceType -- POST -- 有参
export const reqChooseFaceType = (data) => request({ url: `/ent/company/chooseFaceType`, method: "POST", data })

// 企业认证 —— 生成法人人脸二维码（云票据APP版） /ent/company/createLegalFaceQRCode -- POST -- 无参数
export const reqLegalFaceQRCode = () => request({ url: `/ent/company/createLegalFaceQRCode`, method: "POST" })

// 企业认证 —— 生成法人人脸二维码（微信版） /ent/company/createBdFaceQRCode -- POST -- 无参数
export const reqLegalFaceWechat = () => request({ url: `/ent/company/createBdFaceQRCode`, method: "POST" })

// 企业认证 —— 发送法人人脸短信 /ent/company/createLegalFaceShortMsg -- POST -- 请求头参数：Authorization
export const reqLegalFaceShortMsg = () => request({ url: `/ent/company/createLegalFaceShortMsg`, method: "POST" })

// 企业认证 —— 上一步 （法人人脸页面按钮）/ent/company/lastStep -- POST -- 无参数
export const reqLegalFaceBack = () => request({ url: `/ent/company/lastStep`, method: "POST" })

// 企业认证 —— 人脸失败重新 /ent/company/faceResetStatus -- POST
export const reqFaceResetStatus = () => request({url: `/ent/company/faceResetStatus`, method: "POST"})

// 企业认证 —— 绑定对公账户 —— 查询大额行号列表 /utils/bankBigNumber/list -- POST -- 有参
export const reqBankBigNumber = (data) => request({ url: `/utils/bankBigNumber/list`, method: "POST", data })

// 添加或修改对公账户 /ent/bankAccount/saveOrUpdate -- POST -- 有参
export const reqBankAccountSave = (data) => request({ url: `/ent/bankAccount/saveOrUpdate`, method: "POST", data })

// 远程文件下载到浏览器 /utils/download/telefileToBrowser -- GET -- 有参
export const reqTelefileToBrowser = (params, config) => request({ url: `/utils/download/telefileToBrowser`, method: "GET", params, config })
