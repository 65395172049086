// 对于axios进行二次封装
import axios from "axios";
// 引入进度条
// start: 进度条开始   done：进度条结束
import nprogress from "nprogress";
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'; // 引入错误提示
import resetMessage from "@/assets/js/message"
// 在当前模块中引入store
import store from '@/store'
// 全局状态码以及请求规范
import { REQUEST_SUCCESS, AUTH_ERROR, AUTH_EXPIRE, COMPANY_AUTH, SSO_LOGIN_IP } from './globalStatus'

// 1: 利用axios对象的方法create， 去创建一个axios实例
// 2: request就是axios，只不过可以稍微配置一下
const requests = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

requests.interceptors.request.use((config) => {
  // 获取本地token
  let token = localStorage.getItem('token')
  // 将token添加到请求头中
  if (token) {
    config.headers.Authorization = token
  }
  // 进度条开始
  nprogress.start()
  return config
})
requests.interceptors.response.use((res) => {
  // 进度条结束
  nprogress.done()
  let data = res.data;
  if (!data) {
    return Promise.reject(new Error('server error'))
  }
  switch (data.code) {
    case REQUEST_SUCCESS:
      return data
    case AUTH_ERROR:
      window.location.href = `${SSO_LOGIN_IP}/?path=${location.pathname}&userPlatform=LX10001`
      //跳转登录
      break;
    case AUTH_EXPIRE:
      //清除本地localstorage缓存跳转sso单点登录
      localStorage.removeItem('token')
      window.location.href = `${SSO_LOGIN_IP}/?path=${location.pathname}&userPlatform=LX10001`
      break;
    case COMPANY_AUTH:
      //跳转企业认证
      break;
    default:
      return Promise.reject(new Error(data.msg)).catch(err => {
        resetMessage.error(data.msg)
        return data
      })
  }
}, (error) => {
  return Message.error(error)
})

export default requests
