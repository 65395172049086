import Seo from "@/views/users/Seo.vue";
import WikiDetail from "@/views/news/WikiDetail.vue" //专题详情
import NewsDetail from '@/views/news/NewsDetail.vue' //新闻详情

export default [
  {
    path: "/seo",
    component: Seo
  },
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/index", // 首页
    name: "index",
    component: () => import("@/views/Index.vue")
  },
  {
    path: "/viewApp", // 手机端下载入口
    name: "viewApp",
    component: () => import("@/views/phone/ViewApp.vue")
  },
  {
    path: "/productList", // 产品列表
    name: "productList",
    component: () => import("@/views/product/ProductList.vue")
  },
  {
    path: "/discount",
    name: "discount",
    component: () => import("@/views/discount/Discount.vue")
  },
  {
    path: "/attestation", // 企业认证
    component: () => import("@/views/attestation/Attestation")
  },
  {
    path: "/news", //新闻资讯
    name: "news",
    component: () => import("@/views/news/NewsInfo.vue"),
  },
  {
    path: '/company/:id', // 新闻列表
    name: "company",
    component: () => import("@/views/news/NewsWiki.vue"),
    meta: { activeMenu: '/news' }
  },
  {
    path: '/wikiDetail', // 专题详情
    name: "wikiDetail",
    component: WikiDetail,
  },
  {
    path: '/article/article/:id', // 新闻详情
    name: "article",
    component: NewsDetail,
  },
  {
    path: '/help/:id', // 帮助中心
    name: "Help",
    component: () => import("@/views/help/Help.vue"),
  },
  {
    path: '/companyBrief', // 公司简介
    name: "companyBrief",
    component: () => import("@/views/aboutUs/CompanyBrief.vue"),
  },
  {
    path: '/contactUs', // 联系我们
    name: "contactUs",
    component: () => import("@/views/aboutUs/ContactUs.vue"),
  },
  {
    path: '/honor', // 荣誉资质
    name: "honor",
    component: () => import("@/views/aboutUs/Honor.vue"),
  },
  {
    path: '/partners', // 合作伙伴
    name: "partners",
    component: () => import("@/views/aboutUs/Partners.vue"),
  },
  {
    path: '/history', // 发展历程
    name: "history",
    component: () => import("@/views/aboutUs/History.vue"),
  },
  {
    path: '/personal', // 个人中心
    name: "personal",
    redirect: "/personal/enterpriseOverview",
    component: () => import("@/views/personal/Personal.vue"),
    children: [
      {
        path: "enterpriseOverview", // 企业总览
        component: () => import("@/views/personal/EnterpriseOverview.vue"),
      },
      {
        path: "safety", // 账密管理
        component: () => import("@/views/personal/AccountSafety.vue")
      },
      {
        path: "company", // 企业信息
        component: () => import("@/views/personal/CompanyInfo.vue")
      },
      {
        path: "accountPublic", // 对公账户
        component: () => import("@/views/personal/AccountPublic.vue")
      },
      {
        path: "accountAdd/:id?", // 对公账户 - 添加
        component: () => import("@/views/personal/AccountAdd.vue"),
        props: true
      },
      {
        path: "order", // 订单管理
        redirect: "order/silver",
        component: () => import("@/views/personal/Order.vue"),
        children: [
          {
            path: 'silver',
            component: () => import("@/views/personal/components/OrderSilver.vue")
          },
          {
            path: 'business',
            component: () => import("@/views/personal/components/OrderBusiness.vue")
          }
        ]
      },
      {
        path: "messageSystem", // 系统消息
        component: () => import("@/views/personal/MessageSystem.vue")
      },
      {
        path: "messagePrivate", // 私信消息
        component: () => import("@/views/personal/MessagePrivate.vue")
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import("@/views/status/NotFound.vue")
  }
]
