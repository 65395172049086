import Vue from "vue"
import Vuex from "vuex"
// 登录与注册的模块
import users from "./modules/users"
// 新闻模块
import news from "./modules/news"
import productList from "./modules/productList"

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    users,
    news,
    productList
  }
})


