<template>  <div style="position: sticky; top: 0; z-index: 999">
    <div class="header" :class="{ header1: headTrans, header2: header2Tf }">
      <div class="header_left">
        <!-- logo -->
        <div
          v-if="headTrans == false"
          class="logo logoQt"
          @click="changeGL"
        ></div>
        <!-- 变量配置class 替换背景图片 -->
        <div
          v-else
          class="logo"
          :class="{ logo1: logo2Bg1, logo2: logo2Bg }"
          @click="
            $route.path == '/index' ? $router.go(0) : $router.push('/index')
          "
        ></div>

        <!-- headTab栏 -->
        <el-menu
          :default-active="onRoutes"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          :text-color="headTrans ? '#fff' : '#333333'"
          :active-text-color="headTrans ? '#fff' : '#C70009'"
          router
        >
          <el-menu-item index="/index">
            <span class="head_act" :class="headAboutUs ? 'db_css' : ''"
              >首页</span
            >
          </el-menu-item>
          <el-menu-item index="#" @click="handleGoDiscount">
            <span class="head_act">乐享直贴通</span>
          </el-menu-item>
          <el-menu-item index="##">
            <span class="head_act" @click="getCrossLogin">
              <a :href="jumpUrl" target="_blank" rel="">乐享云贷</a>
            </span>
          </el-menu-item>
          <el-menu-item index="###">
            <span class="head_act">
              <a
                href="http://www.lxdigits.com/work/worklxcx/?activeName=second"
                target="_blank"
                rel=""
                >供应链票据</a
              >
            </span>
          </el-menu-item>
          <el-menu-item index="####">
            <span class="head_act">
              <a href="http://cx.cpiaoju.com/" target="_blank" rel=""
                >乐享承信</a
              >
            </span>
          </el-menu-item>
          <el-menu-item index="#####">
            <span class="head_act">
              <a href="https://lxyd.lxdt.com/" target="_blank" rel="">乐享ABS</a>
            </span>
          </el-menu-item>
          <el-menu-item index="/news">
            <span class="head_act">新闻资讯</span>
          </el-menu-item>
          <el-submenu popper-class="head_li" index="6">
            <template slot="title">
              <span class="head_act">帮助中心</span>
            </template>
            <el-menu-item index="/help/0">注册认证</el-menu-item>
            <el-menu-item index="/help/1">产品服务</el-menu-item>
            <el-menu-item index="/help/6">行业攻略</el-menu-item>
          </el-submenu>
          <el-submenu popper-class="head_li" index="7">
            <template slot="title">
              <span class="head_act" :class="headAboutUs ? 'db_css' : ''"
                >关于我们</span
              >
            </template>
            <el-menu-item index="/companyBrief">公司简介</el-menu-item>
            <el-menu-item index="/history">发展历程</el-menu-item>
            <el-menu-item index="/honor">荣誉资质</el-menu-item>
            <el-menu-item index="/partners">合作伙伴</el-menu-item>
            <el-menu-item index="/contactUs">联系我们</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <!-- 右侧联系电话及登录注册 -->
      <div class="header_right">
        <div class="header_tel">
          <div v-if="headTrans == false" class="tel telQt"></div>
          <div
            v-else
            class="tel"
            :class="{ tel1: logo2Bg1, tel2: logo2Bg }"
          ></div>
        </div>
        <div class="tel_text">400-628-7087</div>
        <div class="header_btn head_us" v-if="token">
          <el-button type="text">
            <img
              style="width: 17px; vertical-align: middle"
              src="@/assets/img/icons/header_user.png"
              alt=""
            />
            账户中心
            <img
              style="width: 14px; vertical-align: -webkit-baseline-middle"
              src="@/assets/img/icons/header_xl.png"
              alt=""
            />
          </el-button>
          <div class="head_xl">
            <div class="head_usetel" v-if="beforeNum != null">
              {{ userPhone.left }} ****{{ userPhone.right }}
            </div>
            <div class="head_usetel" v-else></div>
            <el-button @click="goPerson" style="color: #333">
              <img src="@/assets/img/icons/qyzl.png" alt="" />企业总览
            </el-button>
            <el-button @click="dialogVisible = true" style="color: #333">
              <img src="@/assets/img/icons/tcdl.png" alt="" />退出登录
            </el-button>
          </div>
        </div>
        <div class="header_btn" @click="handleGoLogin" v-else>
          <el-button type="text">登录/注册</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>确认要退出吗?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="" type="primary" @click="handleLogout"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
// reqCompanyStatus: 企业状态查询 | reqLogout: 退出登录 | 单点登录
import { reqCompanyStatus, reqLogout, reqCrossLogin } from "@/api";
export default {
  mounted() {
    // 滚动距离控制 头部样式
    window.addEventListener("scroll", this.handleScroll, true);
    // 处理手机号
    this.phoneOperate();
    if (this.token) {
      this.getCrossLogin();
    }
  },
  updated() {
    this.token = localStorage.getItem("token");
  },
  destroyed() {
    // 销毁 滚动距离
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      beforeNum:
        this.$route.query.userPhone ||
        localStorage.getItem("userPhone") ||
        null, // 未处理手机号
      userPhone: {
        // 已处理手机号
        left: "",
        right: "",
      },
      activeMenu: false, // 控制导航高亮
      scroll: "", // 屏幕高度
      header2Tf: false, // 控制头部样式
      logo2Bg: false, // 控制logo图片样式
      logo2Bg1: true, // 控制logo图片样式
      headTrans: false, // 控制不同页面两种头部样式
      headAboutUs: true, // 控制关于我们导航底部特殊样式
      token: localStorage.getItem("token") || null,
      dialogVisible: false, // 退出登录二次确认弹窗
      jumpUrl: "https://lxyd.lxdt.com/", // 跳转乐享云贷
    };
  },
  computed: {
    onRoutes() {
      const route = this.$route;
      let { meta, path } = route;
      // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
      // meta中 有activeMenu 字段的页面，都会显示高亮
      // console.log(meta)
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      // 解决因seo插件引起的路由末尾多个'/'导致不高亮问题
      if (path.charAt(path.length - 1) == "/") {
        path = path.slice(0, path.length - 1);
      }
      return path;
    },
  },
  watch: {
    // 监听route变化以便改变头部样式
    $route() {
      this.checkRoute();
      this.beforeNum =
        this.$route.query.userPhone || localStorage.getItem("userPhone"); // 未处理手机号
      this.phoneOperate();
    },
  },
  methods: {
    // 单点登录
    async getCrossLogin() {
      let params = {
        // LX10001：云票据；LX10002：乐享云贷
        targetPlatform: "LX10002",
      };
      if (this.token) {
        let res = await reqCrossLogin(params);
        if (res.code == 10000) {
          localStorage.setItem("access_token", res.data.access_token);
          // 跳转乐享云贷
          this.jumpUrl =
            res.data.returnPath +
            "?token=" +
            localStorage.getItem("access_token") +
            "&userPhone=" +
            localStorage.getItem("userPhone");
        }
      } else {
        this.jumpUrl = "https://lxyd.lxdt.com/"; // 乐享云贷生产地址
      }
    },
    // 去登录
    handleGoLogin() {
      let path = this.$route.path;
      // window.location.href = process.env.VUE_APP_SSO_LOGIN + "/home/login?path=" + path
      window.location.href =
        process.env.VUE_APP_SSO_LOGIN +
        "/?path=" +
        path +
        "&userPlatform=LX10001";
    },
    // 跳转企业认证或个人中心
    async goPerson() {
      let result = await reqCompanyStatus();
      // 企业认证通过跳转个人中心
      if (result.data.status == 5) {
        this.$router.push("/personal");
      } else {
        // 否则跳转企业认证
        this.$router.push("/attestation");
      }
    },
    // 去直贴通
    async handleGoDiscount() {
      let result = await reqCompanyStatus();
      if (result.data.status == 5) {
        this.$router.push("/discount");
      } else {
        this.$router.push("/attestation");
      }
    },
    // 退出登录——清除token跳转首页
    async handleLogout() {
      this.dialogVisible = false;
      let result = await reqLogout();
      if (result.code !== 10000) {
        this.$message.error(result.msg);
      } else {
        localStorage.clear();
        this.$message.success("退出成功");
        this.$router.push({ name: "index", query: { noToken: true } });
      }
    },
    // 手机号处理
    phoneOperate() {
      if (this.beforeNum != null) {
        this.userPhone.left = this.beforeNum.substring(0, 3);
        this.userPhone.right = this.beforeNum.substring(7);
      }
    },
    // 首页/关于我们页面均为透明样式导航
    checkRoute() {
      if (
        this.$route.path == "/index" ||
        this.$route.path == "/index/" ||
        this.$route.path == "/companyBrief" ||
        this.$route.path == "/companyBrief/" ||
        this.$route.path == "/history" ||
        this.$route.path == "/history/" ||
        this.$route.path == "/honor" ||
        this.$route.path == "/honor/" ||
        this.$route.path == "/partners" ||
        this.$route.path == "/partners/" ||
        this.$route.path == "/contactUs" ||
        this.$route.path == "/contactUs/" ||
        this.$route.path == "/productList" ||
        this.$route.path == "/viewApp" ||
        this.$route.path == "/viewApp/"
      ) {
        this.headTrans = true;
      } else {
        this.headTrans = false;
      }
      if (
        this.$route.path == "/index" ||
        this.$route.path == "/index/" ||
        this.$route.path == "/companyBrief" ||
        this.$route.path == "/history" ||
        this.$route.path == "/honor" ||
        this.$route.path == "/partners" ||
        this.$route.path == "/contactUs" ||
        this.$route.path == "/companyBrief/" ||
        this.$route.path == "/history/" ||
        this.$route.path == "/honor/" ||
        this.$route.path == "/partners/" ||
        this.$route.path == "/contactUs/"
      ) {
        this.headAboutUs = true;
      } else {
        this.headAboutUs = false;
      }
    },
    // tab栏切换
    handleSelect(key, keyPath) {},
    // 解决点击logo取消高亮问题
    changeGL() {
      this.$router.push("/index");
    },
    // 滑动事件
    handleScroll() {
      this.scroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scroll == 120 || this.scroll > 120) {
        this.header2Tf = true;
        this.logo2Bg = true;
        this.logo2Bg1 = false;
        this.headAboutUs = false;
      } else {
        this.header2Tf = false;
        this.logo2Bg = false;
        this.logo2Bg1 = true;
        this.headAboutUs = true;
      }
    },
  },
};
</script>
<style scoped lang="less">
.header {
  height: 70px;
  background: #fff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #f5f7fa;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  position: sticky;
  top: 0;
  z-index: 999;

  .header_left {
    display: flex;

    .logo {
      display: flex;
      width: 246px;
      margin-right: 40px;
      height: 70px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .logoQt {
      background: url("@/assets/img/index/LOGO.png") no-repeat;
      background-size: 216px 40px;
      background-position: 30px 50%;
    }

    .logo1 {
      background: url("@/assets/img/index/headerLOGO.png") no-repeat;
      background-size: 216px 40px;
      background-position: 30px 50%;
    }

    .logo2 {
      background: url("@/assets/img/index/LOGO.png") no-repeat;
      background-size: 216px 40px;
      background-position: 30px 50%;
    }

    .el-menu {
      background: none;
      border-bottom: none;

      a {
        display: block;
      }

      li.el-menu-item {
        height: auto;
        line-height: normal;
        font-size: 16px;
        cursor: inherit;
        padding: 0 10px;
        border-bottom: none;
        cursor: pointer;

        .is-active {
          border-bottom: none !important;
          border-top: 4px;
          width: calc(100% - 14px);
        }
      }

      .el-menu-item:hover {
        background-color: rgba(0, 0, 0, 0);
      }

      .is-active:hover {
        background-color: rgba(0, 0, 0, 0);
      }

      .head_act {
        display: block;
        height: 70px;
        line-height: 70px;
        box-sizing: border-box;
        // min-width: 60px;
        text-align: center;
      }
    }

    /deep/ .el-icon-arrow-down:before {
      display: none;
    }

    /deep/ .el-menu.el-menu--horizontal {
      border: none;
      flex: 1;
    }

    /deep/ .el-menu--horizontal > .el-menu-item,
    /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
      height: 70px;
      line-height: 70px;
      font-size: 16px;
      color: #fff;
      border-bottom: none;
      padding: 0 10px;
    }

    /deep/ .el-menu--horizontal > .el-menu-item:hover span,
    /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover span,
    /deep/
      .el-menu--horizontal
      > .el-submenu
      .el-submenu__title:hover
      .head_act
      a {
      color: #e40012;
    }

    /deep/ .el-menu--horizontal > .el-menu-item.is-active,
    /deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
      border-bottom: none;
      border-bottom-color: transparent;
      color: #e40012 !important;
    }

    /deep/ .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
      display: none;
    }

    /deep/.is-active .head_act {
      display: block;
      height: 70px;
      box-sizing: border-box;
      color: #e40012;
      // min-width: 60px;
      text-align: center;
    }

    /deep/.is-active .head_act.db_css {
      color: #fff;
    }

    /deep/.is-active .head_act.db_css::after {
      content: "";
      background: url("@/assets/img/icons/head_.png") no-repeat;
      background-size: 100%;
      height: 8px;
      width: 60px;
      margin: -16px auto 0;
    }

    /deep/.is-active .head_act::after {
      content: "";
      display: block;
      height: 8px;
      width: 60px;
      background: url("@/assets/img/icons/head_check.png") no-repeat;
      background-size: 100%;
      margin: -16px auto 0;
    }

    /deep/.router-link-exact-active,
    /deep/.router-link-active {
      color: #333;
      text-decoration: none;
    }
  }

  .header_right {
    display: flex;
    justify-content: flex-end;

    .header_tel {
      width: 18px;
      height: 70px;
      margin-right: 5px;

      // 更换客服电话
      .tel {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .telQt {
        width: 100%;
        background: url("@/assets/img/icons/tele2.png") no-repeat;
        background-size: 18px 18px;
        background-position: 0 50%;
      }

      .tel1 {
        background: url("@/assets/img/icons/telephone.png") no-repeat;
        background-size: 18px 18px;
        background-position: 0 50%;
      }

      .tel2 {
        background: url("@/assets/img/icons/tele2.png") no-repeat;
        background-size: 18px 18px;
        background-position: 0 50%;
      }
    }

    .tel_text {
      height: 70px;
      font-size: 24px;
      font-weight: 700;
      color: #333;
      line-height: 70px;
      margin-right: 35px;
      font-family: D-DIN-PRO-ExtraBold, D-DIN-PRO;
    }

    .header_btn {
      width: 138px;
      height: 70px;
      position: relative;

      /deep/.el-button--text {
        color: #fff !important;
        font-size: 16px;
        width: 100%;
        height: 100%;
        background: #e40012;
      }

      /deep/ .el-button {
        border-radius: 0;
      }

      /deep/ .el-button:hover {
        opacity: 0.8;
      }

      .head_xl {
        display: none;
        width: 178px;
        height: 188px;
        background: url(@/assets/img/index/juxing.png) no-repeat;
        background-size: 100%;
        position: absolute;
        top: 33px;
        left: -57px;
        z-index: 99;

        > div {
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          color: #333;
        }

        .head_usetel {
          width: 144px;
          color: #999999;
          line-height: 40px;
          border-bottom: 1px solid #f2f3f5;
          margin: 30px auto 10px;
        }

        img {
          width: 17px;
          margin-right: 7px;
          vertical-align: text-bottom;
        }

        /deep/.el-button {
          line-height: 40px;
          width: 146px;
          margin: 0 16px;
          border: none;
          padding: 0;
        }

        /deep/.el-button:focus,
        /deep/.el-button:hover {
          background: transparent;
          color: #333;
        }
      }
    }

    .head_us:hover .head_xl {
      display: block;
    }
  }
}

/deep/.el-dialog__wrapper {
  height: 100vh;

  .el-dialog {
    margin-top: 30vh !important;
  }

  .el-button--default:focus,
  .el-button--default:hover {
    color: #606266;
    border-color: #dcdfe6;
    background-color: transparent;
  }

  .el-button {
    height: 32px;
    padding: 0;
    width: 63px;

    span {
      height: 32px;
      line-height: 32px;
    }
  }

  .el-button:hover {
    opacity: 0.8;
  }
}

.header1 {
  position: fixed !important;
  z-index: 999;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(11px);
  // border-bottom: none;

  .header_right {
    .tel_text {
      color: #fff;
    }

    .header_btn {
      background: rgba(255, 255, 255, 0.2);

      /deep/.el-button--text {
        background: transparent;
      }
    }
  }
}

.header1:hover,
.header2 {
  background-color: #fff !important;
  box-shadow: 0px 0px 5px rgb(151 151 151 / 30%);

  .header_right {
    .tel_text {
      color: #333;
    }

    .header_btn {
      /deep/.el-button--text {
        background: #e40012;
      }
    }
  }

  .el-menu-item {
    color: #333333 !important;
  }

  /deep/.el-menu--horizontal > .el-menu-item,
  /deep/.el-menu--horizontal > .el-submenu .el-submenu__title {
    color: #333333 !important;
  }

  /deep/.is-active .head_act.db_css {
    color: #e40012 !important;
  }

  /deep/.is-active .head_act::after {
    content: "";
    display: block;
    height: 8px;
    width: 60px;
    background: url("@/assets/img/icons/head_check.png") no-repeat !important;
    background-size: 100% !important;
    margin: -16px auto 0 !important;
  }
}

.header1:hover .header_right > div .tel1 {
  width: 100%;
  background: url("@/assets/img/icons/tele2.png") no-repeat;
  background-size: 18px 18px;
  background-position: 0 50%;
}

.header1:hover .header_left .logo1 {
  background: url("@/assets/img/index/LOGO.png") no-repeat;
  background-size: 216px auto;
  background-position: 30px 50%;
}

a {
  text-decoration: none;
  color: #333;
}
</style>

<style lang="less">
// 解决子菜单的样式改不掉的问题
.el-menu--horizontal.head_li {
  .el-menu--popup {
    box-shadow: 0px 2px 20px 2px rgba(0, 55, 148, 0.15) !important;
    min-width: auto !important;
    margin-top: 0 !important;
    padding: 30px 0 10px;
  }

  .el-menu .el-menu-item,
  .el-menu .el-submenu__title {
    color: #333 !important;
    padding: 0 40px;
    font-size: 14px;
    height: 22px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .el-menu .el-menu-item a,
  .el-menu .el-submenu__title a {
    color: #333;
  }

  .el-menu .el-menu-item:hover,
  .el-menu .el-submenu__title:hover,
  .el-menu .el-menu-item:hover a,
  .el-menu .el-submenu__title:hover a,
  .el-menu-item.is-active a,
  a:hover {
    color: #e40012 !important;
  }
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent !important;
}

.el-menu--horizontal .el-menu .el-submenu.is-active .head_act,
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: #e40012 !important;
}

a {
  text-decoration: none;
}
</style>
