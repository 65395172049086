import Vue from "vue";
import VueRouter from "vue-router"
import routes from "./routes"
Vue.use(VueRouter)

//解决vue路由重复导航错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 解决跳转页面后滚动条位置还在上个页面的问题
const scrollBehavior = function scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }else {
      return { x: 0, y: 0 }
   }
};
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior
})

export default router

/* router.beforeEach((to, from, next) => {
  if (['/index', '/login', '/register', '/news', '/company', '/attestation'].includes(to.path)) {
    next();
  } else {
    let token = localStorage.getItem("token");
    if (!token) {
      next('/login')
    } else {
      next()
    }
  }
}); */
router.afterEach((to, from) => {
  let token = to.query.token
  if (to.query.token) {
    localStorage.setItem('token', token)
    // console.log(to.query.token)
  }
  if (to.query.userPhone) {
  let userPhone = to.query.userPhone
    localStorage.setItem('userPhone', userPhone)
  }
})

